import React from "react";
import Helmet from 'react-helmet';
import favicon from '../../images/favicon.ico';

import pdf_file from "../../images/pdf/1.4.2-kvalitetspolicy.pdf";

function Kvalitetspolicy(){
    return(
        <>
            <Helmet>
                <title>Kvalitetspolicy | Optosweden</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="kvalitetspolicy" />
                <meta name="keywords" content="Optosweden" />
                <meta property="og:title" content="Optosweden AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="kvalitetspolicy" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.optosweden.se/pdf/kvalitetspolicy" />
                <link rel="cannonical" href="https://www.optosweden.se/pdf/kvalitetspolicy" />
            </Helmet>

            <iframe 
                title="1.4.2 kvalitetspolicy"
                src={pdf_file}
                style={{height: '100vh',
                        width: '100vw',
            }}>
            <p>This browser does not support PDFs. Please download the PDF to view it: Download PDF </p>
            </iframe>

        </>
    )
}
export default Kvalitetspolicy;